import {createSlice} from '@reduxjs/toolkit';

export interface UserInfo {
    userId: number;
    sub: string;
    username: string;
    email: string;
    role: string;
    picture: string;
}

const initialState = {
    userId: null,
    username: null,
    email: null,
    sub: null,
    role: null,
    picture: null,
} as UserInfo

export const userInfoSlice = createSlice({
    name: 'userinfo',
    initialState,
    reducers: {
        SET_USERINFO: (state, action) => {
            state.userId = action.payload.userId;
            state.username = action.payload.username;
            state.email = action.payload.email;
            state.sub = action.payload.sub;
            state.role = action.payload.role;
            state.picture = action.payload.picture;
        },
        DELETE_USERINFO: (state) => {
            state.userId = initialState.userId;
            state.username = initialState.username;
            state.email = initialState.email;
            state.sub = initialState.sub;
            state.role = initialState.role;
            state.picture = initialState.picture;
        },
    }
})

export const { SET_USERINFO, DELETE_USERINFO } = userInfoSlice.actions;
