import storage from "redux-persist/lib/storage";
import {configureStore, combineReducers} from "@reduxjs/toolkit";
import {persistReducer} from "redux-persist";
import {authInfoSlice} from "./AuthInfoSlice";
import {userInfoSlice} from "./UserInfoSlice";
import {pkceSlice} from "./PkceSlice";

const persistConfig = {
    key: "root",
    storage
};

const reducers = combineReducers({
    authInfo: authInfoSlice.reducer,
    userInfo: userInfoSlice.reducer,
    pkce: pkceSlice.reducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {serializableCheck: false}
    ),
    devTools: process.env.NODE_ENV !== 'production'
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>

export default store;
