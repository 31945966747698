import { createSlice } from '@reduxjs/toolkit';
import {AuthUtils} from "src/common/AuthUtils";

export interface AuthInfo {
    accessToken: string
    isAuthenticated: boolean
    exp: number
}

const initialState = {
    accessToken: null,
    isAuthenticated: false,
    exp: null,
} as AuthInfo

export const authInfoSlice = createSlice({
    name: 'accessToken',
    initialState,
    reducers: {
        SET_TOKEN: (state, action) => {
            state.isAuthenticated = true;
            state.accessToken = action.payload;
            state.exp = AuthUtils.decodeJwt(action.payload).exp;
        },
        DELETE_TOKEN: (state) => {
            state.isAuthenticated = false;
            state.accessToken = null;
            state.exp = null
        },
    }
})

export const { SET_TOKEN, DELETE_TOKEN } = authInfoSlice.actions;
