import {createSlice} from '@reduxjs/toolkit';

interface Pkce {
    state: string,
    codeVerifier: string,
}

const initialState = {
    state: null,
    codeVerifier: null,
} as Pkce

export const pkceSlice = createSlice({
    name: 'pkce',
    initialState,
    reducers: {
        SET_PKCE_STATE: (state, action) => {
            state.state = action.payload;
        },
        SET_PKCE_CODE_VERIFIER: (state, action) => {
            state.codeVerifier = action.payload;
        },
        DELETE_PKCE_STATE: (state) => {
            state.state = initialState.state;
        },
        DELETE_PKCE_CODE_VERIFIER: (state) => {
            state.codeVerifier = initialState.codeVerifier;
        },
    }
})

export const { SET_PKCE_STATE, SET_PKCE_CODE_VERIFIER, DELETE_PKCE_STATE, DELETE_PKCE_CODE_VERIFIER } = pkceSlice.actions;
