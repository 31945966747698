import { useRoutes } from 'react-router-dom';
import router from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import {AuthUtils} from "src/common/AuthUtils";
import {useSelector} from "react-redux";
import {RootState} from "./Store";

function App() {
    const authInfo = useSelector((state: RootState) => state.authInfo)
    const isAuthenticated = authInfo.isAuthenticated
    const content = useRoutes(router(isAuthenticated));

    return (
            <ThemeProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline/>
                    {content}
                </LocalizationProvider>
            </ThemeProvider>
    );
}

export default App;
