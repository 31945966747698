import {useContext, useEffect, useState} from 'react';

import {
  ListSubheader,
  alpha,
  Box,
  List,
  styled,
  Button,
  ListItem
} from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { SidebarContext } from 'src/contexts/SidebarContext';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import CheckBoxTwoToneIcon from '@mui/icons-material/CheckBoxTwoTone';
import ChromeReaderModeTwoToneIcon from '@mui/icons-material/ChromeReaderModeTwoTone';
import WorkspacePremiumTwoToneIcon from '@mui/icons-material/WorkspacePremiumTwoTone';
import CameraFrontTwoToneIcon from '@mui/icons-material/CameraFrontTwoTone';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import {Board} from "src/models/board/board";
import {V1_BOARD_FIND_ALL} from "src/constants/api/v1/board/board";
import {ApiUtils} from "src/api/ApiUtils";

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(1)};

    & > .MuiList-root {
      padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
    }
  }

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-weight: bold;
      font-size: ${theme.typography.pxToRem(12)};
      color: ${theme.colors.alpha.trueWhite[50]};
      padding: ${theme.spacing(0, 2.5)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {

      .MuiListItem-root {
        padding: 1px 0;

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(3.2)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(10)};
            font-weight: bold;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.colors.alpha.trueWhite[70]};
          background-color: transparent;
          width: 100%;
          justify-content: flex-start;
          padding: ${theme.spacing(1.2, 3)};

          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            color: ${theme.colors.alpha.trueWhite[30]};
            font-size: ${theme.typography.pxToRem(20)};
            margin-right: ${theme.spacing(1)};
          }
          
          .MuiButton-endIcon {
            color: ${theme.colors.alpha.trueWhite[50]};
            margin-left: auto;
            opacity: .8;
            font-size: ${theme.typography.pxToRem(20)};
          }

          &.active,
          &:hover {
            background-color: ${alpha(theme.colors.alpha.trueWhite[100], 0.06)};
            color: ${theme.colors.alpha.trueWhite[100]};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(7)};
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px 0;

            .MuiButton-root {
              padding: ${theme.spacing(0.8, 3)};

              .MuiBadge-root {
                right: ${theme.spacing(3.2)};
              }

              &:before {
                content: ' ';
                background: ${theme.colors.alpha.trueWhite[100]};
                opacity: 0;
                transition: ${theme.transitions.create([
                  'transform',
                  'opacity'
                ])};
                width: 6px;
                height: 6px;
                transform: scale(0);
                transform-origin: center;
                border-radius: 20px;
                margin-right: ${theme.spacing(1.8)};
              }

              &.active,
              &:hover {

                &:before {
                  transform: scale(1);
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
    const {closeSidebar} = useContext(SidebarContext);
    const [boards, setBoards] = useState<Board[]>([]);

    const apiUtils = ApiUtils.getAxiosInstance();



    function setBoardList(){
        apiUtils.get<Board[]>(V1_BOARD_FIND_ALL)
            .then(response => response.data)
            .then(data => setBoards(data));
    }

    useEffect(() => {
        setBoardList()
    }, []);


    return (
    <>
      <MenuWrapper>
        <List component="div">
          <SubMenuWrapper>
                <Box p={2}>
                  <Button
                      to="/articles/write"
                      component={RouterLink}
                      onClick={closeSidebar}
                      rel="noopener noreferrer"
                      variant="contained"
                      color="info"
                      size="small"
                      fullWidth
                  >
                    글쓰기
                  </Button>
                </Box>
          </SubMenuWrapper>
        </List>
        <List
          component="div"
          subheader={
            <ListSubheader component="div" disableSticky>
              Boards
            </ListSubheader>
          }
        >
          <SubMenuWrapper>
            <List component="div">
                {boards.map((board) => (
                    <ListItem key={board.boardId} component="div">
                        <Button
                            disableRipple
                            component={RouterLink}
                            onClick={closeSidebar}
                            to={`/boards/${board.boardId}/articles`}
                            startIcon={<TableChartTwoToneIcon />}
                            state={board}
                        >
                            {board.name}
                        </Button>
                    </ListItem>

                ))}
              {/*<ListItem component="div">*/}
              {/*  <Button*/}
              {/*    disableRipple*/}
              {/*    component={RouterLink}*/}
              {/*    onClick={closeSidebar}*/}
              {/*    to="/dashboards/crypto"*/}
              {/*    startIcon={<BrightnessLowTwoToneIcon />}*/}
              {/*  >*/}
              {/*    Cryptocurrency*/}
              {/*  </Button>*/}
              {/*</ListItem>*/}
              {/*<ListItem component="div">*/}
              {/*  <Button*/}
              {/*    disableRipple*/}
              {/*    component={RouterLink}*/}
              {/*    onClick={closeSidebar}*/}
              {/*    to="/dashboards/messenger"*/}
              {/*    startIcon={<MmsTwoToneIcon />}*/}
              {/*  >*/}
              {/*    Messenger*/}
              {/*  </Button>*/}
              {/*</ListItem>*/}
              {/*  <ListItem component="div">*/}
              {/*    <Button*/}
              {/*      disableRipple*/}
              {/*      component={RouterLink}*/}
              {/*      onClick={closeSidebar}*/}
              {/*      to="/management/transactions"*/}
              {/*      startIcon={<TableChartTwoToneIcon />}*/}
              {/*    >*/}
              {/*      Transactions List*/}
              {/*    </Button>*/}
              {/*  </ListItem>*/}
            </List>
          </SubMenuWrapper>
        </List>
        {/*<ArticleList*/}
        {/*  component="div"*/}
        {/*  subheader={*/}
        {/*    <ListSubheader component="div" disableSticky>*/}
        {/*      Management*/}
        {/*    </ListSubheader>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <SubMenuWrapper>*/}
        {/*    <ArticleList component="div">*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/management/transactions"*/}
        {/*          startIcon={<TableChartTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Transactions ArticleList*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*    </ArticleList>*/}
        {/*  </SubMenuWrapper>*/}
        {/*</ArticleList>*/}
        {/*<ArticleList*/}
        {/*  component="div"*/}
        {/*  subheader={*/}
        {/*    <ListSubheader component="div" disableSticky>*/}
        {/*      Accounts*/}
        {/*    </ListSubheader>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <SubMenuWrapper>*/}
        {/*    <ArticleList component="div">*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/management/profile/details"*/}
        {/*          startIcon={<AccountCircleTwoToneIcon />}*/}
        {/*        >*/}
        {/*          User Profile*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/management/profile/settings"*/}
        {/*          startIcon={<DisplaySettingsTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Account Settings*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*    </ArticleList>*/}
        {/*  </SubMenuWrapper>*/}
        {/*</ArticleList>*/}
        {/*<ArticleList*/}
        {/*  component="div"*/}
        {/*  subheader={*/}
        {/*    <ListSubheader component="div" disableSticky>*/}
        {/*      Components*/}
        {/*    </ListSubheader>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <SubMenuWrapper>*/}
        {/*    <ArticleList component="div">*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/buttons"*/}
        {/*          startIcon={<BallotTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Buttons*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/modals"*/}
        {/*          startIcon={<BeachAccessTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Modals*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/accordions"*/}
        {/*          startIcon={<EmojiEventsTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Accordions*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/tabs"*/}
        {/*          startIcon={<FilterVintageTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Tabs*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/badges"*/}
        {/*          startIcon={<HowToVoteTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Badges*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/tooltips"*/}
        {/*          startIcon={<LocalPharmacyTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Tooltips*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/avatars"*/}
        {/*          startIcon={<RedeemTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Avatars*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/cards"*/}
        {/*          startIcon={<SettingsTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Cards*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/components/forms"*/}
        {/*          startIcon={<TrafficTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Forms*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*    </ArticleList>*/}
        {/*  </SubMenuWrapper>*/}
        {/*</ArticleList>*/}
        {/*<ArticleList*/}
        {/*  component="div"*/}
        {/*  subheader={*/}
        {/*    <ListSubheader component="div" disableSticky>*/}
        {/*      Extra Pages*/}
        {/*    </ListSubheader>*/}
        {/*  }*/}
        {/*>*/}
        {/*  <SubMenuWrapper>*/}
        {/*    <ArticleList component="div">*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/status/404"*/}
        {/*          startIcon={<CheckBoxTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Error 404*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/status/500"*/}
        {/*          startIcon={<CameraFrontTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Error 500*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/status/coming-soon"*/}
        {/*          startIcon={<ChromeReaderModeTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Coming Soon*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*      <ListItem component="div">*/}
        {/*        <Button*/}
        {/*          disableRipple*/}
        {/*          component={RouterLink}*/}
        {/*          onClick={closeSidebar}*/}
        {/*          to="/status/maintenance"*/}
        {/*          startIcon={<WorkspacePremiumTwoToneIcon />}*/}
        {/*        >*/}
        {/*          Maintenance*/}
        {/*        </Button>*/}
        {/*      </ListItem>*/}
        {/*    </ArticleList>*/}
        {/*  </SubMenuWrapper>*/}
        {/*</ArticleList>*/}
      </MenuWrapper>
    </>
  );
}

export default SidebarMenu;
