import jwt_decode from "jwt-decode";
import {useSelector} from "react-redux";
import {RootState} from "../Store";
import {AuthInfo} from "../Store/AuthInfoSlice";


export abstract class AuthUtils {

    public static getUrlParameter(name): string {
        const v_name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + v_name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    public static getAccessToken(): string {
        return useSelector((state: RootState) => state.authInfo).accessToken
    }

    public static decodeJwt(jwt): AuthInfo {
        try{
            return jwt_decode(jwt)
        }catch (e) {
            window.location.href = '/logout'
        }
    }

}



