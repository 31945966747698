import { useContext } from 'react';
import Scrollbar from 'src/components/Scrollbar';
import { SidebarContext } from 'src/contexts/SidebarContext';

import {
    Box,
    Drawer,
    alpha,
    styled,
    Divider,
    useTheme,
    Button,
    lighten,
    darken,
    Tooltip, Avatar, TooltipProps, tooltipClasses, ListItemButton, ListItemAvatar, Grid
} from '@mui/material';

import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import {doc} from "prettier";


const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.colors.alpha.trueWhite[70]};
        position: relative;
        z-index: 7;
        height: 100%;
        padding-bottom: 68px;
`
);

const TooltipWrapper = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.colors.alpha.trueWhite[100],
        color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 'bold',
        borderRadius: theme.general.borderRadiusSm,
        boxShadow:
            '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.colors.alpha.trueWhite[100]
    }
}));

function Sidebar() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();
  const theme = useTheme();

  return (
    <>
      <SidebarWrapper
        sx={{
          display: {
            xs: 'none',
            lg: 'inline-block'
          },
          position: 'fixed',
          left: 0,
          top: 0,
          background:
            theme.palette.mode === 'dark'
              ? alpha(lighten(theme.header.background, 0.1), 0.5)
              : darken(theme.colors.alpha.black[100], 0.5),
          boxShadow:
            theme.palette.mode === 'dark' ? theme.sidebar.boxShadow : 'none'
        }}
      >
        <Scrollbar>
          <Box mt={3}>
            <Box
              mx={2}
              sx={{
                width: 52
              }}
            >
              <Logo />
            </Box>
          </Box>
          <Divider
            sx={{
              mt: theme.spacing(3),
              mx: theme.spacing(2),
              background: theme.colors.alpha.trueWhite[10]
            }}
          />
          <SidebarMenu />
        </Scrollbar>
        <Divider
          sx={{
            background: theme.colors.alpha.trueWhite[10]
          }}
        />
        <Box p={1.5}>
          {/*<Button*/}
          {/*  href="#"*/}
          {/*  target="_blank"*/}
          {/*  rel="noopener noreferrer"*/}
          {/*  variant="contained"*/}
          {/*  color="warning"*/}
          {/*  size="small"*/}
          {/*  fullWidth*/}
          {/*>*/}
          {/*  Hello World*/}



          {/*</Button>*/}
            <Grid container spacing={1}  display="flex" >
                <Grid item xs={12} sm={2} md={2} textAlign={{ sm: 'right' }}>
                    <TooltipWrapper
                        title="Lunit"
                        arrow
                    >
                        <Avatar variant="rounded" alt={"Lunit"} style={{cursor: "pointer"}}
                                src="/static/images/logo/lunit.svg"
                                onClick={() => window.open('https://www.lunit.io', '_blank')}
                        />

                    </TooltipWrapper>
                </Grid>
                <Grid item xs={12} sm={2} md={2} textAlign={{ sm: 'right' }}>
                    <TooltipWrapper
                        title="Flex"
                        arrow
                    >
                        <Avatar variant="rounded" alt={"Flex"} style={{cursor: "pointer"}}
                                src="/static/images/logo/flex.svg"
                                onClick={() => window.open('https://www.flex.team', '_blank')}
                                />

                    </TooltipWrapper>
                </Grid>
                <Grid item xs={12} sm={2} md={2} textAlign={{ sm: 'right' }}>
                    <TooltipWrapper
                        title="Concur"
                        arrow
                    >
                        <Avatar variant="rounded" alt={"SAP Concur"} style={{cursor: "pointer"}}
                                src="/static/images/logo/sap_concur.png"
                                onClick={() => window.open('https://www.concursolutions.com', '_blank')}
                        />
                    </TooltipWrapper>
                </Grid>
                <Grid item xs={12} sm={2} md={2} textAlign={{ sm: 'right' }}>
                    <TooltipWrapper
                        title="Confluence"
                        arrow
                    >
                        <Avatar variant="rounded" alt={"Confluence"} style={{cursor: "pointer"}}
                                src="/static/images/logo/confluence.png"
                                onClick={() => window.open('https://lunit.atlassian.net/wiki', '_blank')}
                        />

                    </TooltipWrapper>
                </Grid>
                <Grid item xs={12} sm={2} md={2} textAlign={{ sm: 'right' }}>
                    <TooltipWrapper
                        title="Jira"
                        arrow
                    >
                        <Avatar variant="rounded" alt={"Jira"} style={{cursor: "pointer"}}
                                src="/static/images/logo/jira.png"
                                onClick={() => window.open('https://lunit.atlassian.net/jira', '_blank')}
                        />
                    </TooltipWrapper>
                </Grid>
                <Grid item xs={12} sm={2} md={2} textAlign={{ sm: 'right' }}>
                    <TooltipWrapper
                        title="Docswave"
                        arrow
                    >
                        <Avatar variant="rounded" alt={"Docswave"} style={{cursor: "pointer"}}
                                src="/static/images/logo/docswave.svg"
                                onClick={() => window.open('https://www.docswave.com', '_blank')}
                        />

                    </TooltipWrapper>
                </Grid>



            </Grid>
        </Box>
      </SidebarWrapper>
      <Drawer
        sx={{
          boxShadow: `${theme.sidebar.boxShadow}`
        }}
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={sidebarToggle}
        onClose={closeSidebar}
        variant="temporary"
        elevation={9}
      >
        <SidebarWrapper
          sx={{
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.white[100]
                : darken(theme.colors.alpha.black[100], 0.5)
          }}
        >
          <Scrollbar>
            <Box mt={3}>
              <Box
                mx={2}
                sx={{
                  width: 52
                }}
              >
                <Logo />
              </Box>
            </Box>
            <Divider
              sx={{
                mt: theme.spacing(3),
                mx: theme.spacing(2),
                background: theme.colors.alpha.trueWhite[10]
              }}
            />
            <SidebarMenu />
          </Scrollbar>
        </SidebarWrapper>
      </Drawer>
    </>
  );
}

export default Sidebar;
