import Axios from "axios";
import {AuthUtils} from "src/common/AuthUtils";
import React from 'react';

export abstract class ApiUtils {
    public static getAxiosInstance() {
         const axiosInstance = Axios.create({
            timeout: 50000,
            headers: {
                "Content-Type": "application/json",
                Authorization : `Bearer ${AuthUtils.getAccessToken()}`
            },
        })

        axiosInstance.interceptors.request.use(
            config => {
                return config;
            },

            err => {
                return Promise.reject(err);
            },
        );

        axiosInstance.interceptors.response.use(
            response => {
                return response
            },
            error => {
                const response = error.response
                switch(error.response.status){
                    case 401:
                        window.location.href = '/logout'
                        break;
                    default:
                        alert(response.data.message)
                        break;
                }
                return Promise.reject(error)
            }
        );

        return axiosInstance
    }
}