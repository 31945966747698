import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';

import { Provider } from 'react-redux';
import store from 'src/Store'
import {PersistGate} from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
const persistor = persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <HelmetProvider>
                <SidebarProvider>
                    <BrowserRouter>
                        <App/>
                    </BrowserRouter>
                </SidebarProvider>
            </HelmetProvider>
        </PersistGate>
    </Provider>
    ,
    document.getElementById('root')
);

serviceWorker.unregister();
